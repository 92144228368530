<template>
    <section id="media" class="Interviews">
        <div class="Wrapper">
            <h2 class="Title Title--h2 Interviews-Title"></h2>
        </div>
        <Slider :interviews="this.$t('interviews.interviews')" />
    </section>
</template>

<script>
import Slider from "./Slider";
import { textAnimation, isInViewport } from "../assets/utils/utils";
export default {
    name: "Interviews",
    components: { Slider },
    data() {
        return {
            isTextTyped: false
        }
    },
    methods: {
        scrollHandler() {
            if (!this.isTextTyped && isInViewport('.Interviews')) {
                this.isTextTyped = true
                const texts = [
                    {
                        selector: '.Interviews-Title',
                        text: this.$t('interviews.title')
                    }
                ]

                textAnimation(texts)
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollHandler)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollHandler)
    }
}
</script>

<style lang="scss">
.Interviews {
    scroll-margin-top: 120px;
    overflow-x: hidden; // Add this to prevent horizontal scroll

    .Wrapper {
        width: 100%;
        overflow: hidden; // Add this to contain children
    }

    @media (min-width: $screen-l) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }

    &-Title {
        height: 38px;
        margin-bottom: 20px;

        @media (min-width: $screen-l) {
            height: 76px;
            margin-bottom: 61px;
        }
    }

    .SwiperSlider {
        padding-left: 19px;
        width: calc(100% - 19px); // Add this to account for padding

        @media (min-width: $screen-l) {
            padding-left: 0;
            margin-left: 150px;
            width: calc(100% - 150px); // Add this to account for margin
        }
    }
}
</style>