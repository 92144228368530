<template>
    <transition name="popup" mode="out-in">
        <div class="TelegramChanAdPopup" v-if="isVisible && showPopup" name="TelegramChanAdPopup">
            <div id="popup-close">
                <button class="close-button" @click="closePopup">×</button>
            </div>
            <div id="popup-content">
                <h1 id="popup-h1">Все секреты медиабаинга – в одном канале!</h1>
                <img id="popup-chalk-line" src="/chalk-line.png" />
                <span id="popup-span">
                    Прокачай свою экспертизу и карьеру: кейсы, стратегии и инсайды от лидеров
                    индустрии!
                </span>
                <div class="popup-button-container">
                    <img src="/cat.png" id="popup-cat-img">
                    <a id="popup-telegram-link" href="https://t.me/+NNcmxljlTWRkYjUy">
                        ПРИСОЕДИНИТЬСЯ
                        <img src="/tg-icon.png" id="popup-tg-icon">
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'TelegramChanAdPopup',
    data() {
        return {
            isVisible: true,
            showPopup: false,
            closeTimer: null,
            showTimer: null
        }
    },
    mounted() {
        this.showTimer = setTimeout(() => {
            this.showPopup = true;
            this.closeTimer = setTimeout(() => {
                this.closePopup();
            }, 6000);
        }, 4000);
    },
    beforeDestroy() {
        if (this.closeTimer) {
            clearTimeout(this.closeTimer);
        }
        if (this.showTimer) {
            clearTimeout(this.showTimer);
        }
    },
    methods: {
        closePopup() {
            this.isVisible = false;
            setTimeout(() => {
                this.$emit('close');
            }, 800);
        }
    }
}
</script>

<style lang="scss">
.popup-enter-active,
.popup-leave-active {
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.popup-enter,
.popup-leave-to {
    opacity: 0;
    transform: translate(-50%, -40%) scale(0.95);
}

.popup-enter-to,
.popup-leave {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.TelegramChanAdPopup {
    position: fixed;
    width: min(600px, 95vw);
    max-width: 90vw;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    background:
        linear-gradient(10deg, rgba(49, 228, 57, 0.2), rgba(0, 0, 0, 1) 60%),
        url('/grid.png'),
        url('/smoke.png'),
        black;
    background-size: 100%, 120%, cover, 100%;
    background-position: center, center, center, center;
    background-repeat: no-repeat, repeat, repeat, no-repeat;
    background-blend-mode: normal, normal, normal, normal;

    #popup-close {
        position: relative;
        width: 100%;
        height: 60px;
        z-index: 3;
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .close-button {
            background: none;
            border: 2px solid rgba(49, 228, 57, 0.5);
            border-radius: 50%;
            color: white;
            font-size: 40px;
            cursor: pointer;
            padding: 0px 16px;
            margin-right: 20px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            font-weight: 200;

            &:hover {
                background: rgba(49, 228, 57, 0.1);
                border-color: rgba(49, 228, 57, 0.8);
                border-width: 2px;
            }
        }
    }

    #popup-content {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        min-height: 300px;
        box-sizing: border-box;
        background: none;
        z-index: 2;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 0;
        }

        #popup-h1 {
            padding: 10px;
            border-radius: 10px;
            font-size: 1.8em;
            z-index: 1;
            font-family: "JetBrainsMono";
            color: var(--color-banner-bg);
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1.2;
        }

        #popup-chalk-line {
            width: 60%;
            height: auto;
            margin-top: -8px;
            z-index: 1;
        }

        #popup-span {
            z-index: 1;
            color: var(--color-text-main);
            font-family: "JetBrainsMono";
            padding: 10px;
            font-size: 1em;
            max-width: 80%;
            line-height: 1.4;
            margin-top: 5px;
        }

        .popup-button-container {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 30px;
            margin-top: 20px;
            z-index: 1;
        }

        #popup-cat-img {
            width: 80px;
            position: absolute;
            left: -15px;
            bottom: -15px;
            z-index: 2;
        }

        #popup-telegram-link {
            background-color: var(--color-text-main2);
            color: black;
            border-radius: 20px;
            position: relative;
            z-index: 1;
            font-size: clamp(16px, 2vw, 24px);
            padding: 12px 25px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 10px;
            text-decoration: none;

            &:hover {
                background-color: var(--color-banner-bg);
            }
        }

        #popup-tg-icon {
            width: 25px;
            height: auto;
        }
    }

    @media screen and (max-width: 1024px) {
        width: 95%;
        margin: 0;

        #popup-content {
            min-height: 280px;
            padding: 15px;

            #popup-h1 {
                font-size: 1.6em;
            }

            #popup-span {
                font-size: 1.1em;
                max-width: 90%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 90%;
        margin: 0;
        top: 40%;

        #popup-close {
            height: 40px;
            padding: 10px;

            .close-button {
                width: 40px;
                height: 40px;
                font-size: 30px;
                margin-right: 15px;
            }
        }

        #popup-content {
            min-height: 220px;
            padding: 15px 10px;

            #popup-h1 {
                font-size: 1.3em;
                padding: 5px;
            }

            #popup-chalk-line {
                width: 70%;
                margin-top: -5px;
            }

            #popup-span {
                font-size: 0.95em;
                max-width: 95%;
                padding: 5px;
            }

            #popup-cat-img {
                width: 50px;
                left: -8px;
                bottom: -8px;
            }

            #popup-telegram-link {
                font-size: 15px;
                padding: 10px 15px;
            }

            #popup-tg-icon {
                width: 18px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        width: 90%;
        top: 30%;

        #popup-content {
            min-height: 200px;

            #popup-h1 {
                font-size: 1.1em;
            }

            #popup-span {
                font-size: 0.9em;
            }

            #popup-telegram-link {
                font-size: 14px;
                padding: 8px 12px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 90%;

        #popup-close {
            height: 50px;
            padding: 10px;
        }

        #popup-content {
            min-height: 250px;
            padding: 15px;

            #popup-h1 {
                font-size: 1.4em;
                padding: 8px;
            }

            #popup-chalk-line {
                width: 80%;
                margin-top: -5px;
            }

            #popup-span {
                font-size: 0.9em;
                max-width: 95%;
                padding: 8px;
            }

            #popup-cat-img {
                width: 60px;
                left: -10px;
                bottom: -10px;
            }

            #popup-telegram-link {
                font-size: 16px;
                padding: 10px 20px;
            }

            #popup-tg-icon {
                width: 20px;
            }
        }
    }
}
</style>
