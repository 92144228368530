<template>
    <div id="app">
        <TelegramChanAdBanner v-if="isRussianLocale" />
        <TelegramChanAdPopup v-if="isRussianLocale" />
        <MainMenu :class="{ 'menu-sticky': isMenuSticky }" />
        <main class="Main">
            <router-view :key="$i18n.locale" />
        </main>
        <TheFooter />
    </div>
</template>
<script>
import MainMenu from "./components/MainMenu";
import BaseModal from "./components/BaseModal";
import TelegramChanAdBanner from "./components/TelegramChanAdBanner.vue";
import TelegramChanAdPopup from "./components/TelegramChanAdPopup.vue";
const TheFooter = () =>
    import("@/components/TheFooter.vue");

export default {
    name: "App",
    components: {
        TelegramChanAdBanner,
        MainMenu,
        TelegramChanAdPopup,
        TheFooter
    },
    data() {
        return {
            isMenuSticky: false,
            isBannerVisible: true
        };
    },
    metaInfo() {
        return {
            title: this.$t("title"),
            meta: [
                {
                    name: "description",
                    content: this.$t("description")
                }
            ]
        };
    },
    computed: {
        isRussianLocale() {
            return this.$i18n.locale === 'ru';
        }
    },
    watch: {
        isRussianLocale: {
            immediate: true,
            handler(isRu) {
                if (!isRu) {
                    this.isMenuSticky = true;
                    document.documentElement.style.setProperty('--banner-visible', '0');
                }
            }
        }
    },
    methods: {
        opened() {
            clearTimeout(this.timer);
        },
        openModal(content) {
            this.$modal.show(
                BaseModal,
                { content },
                {
                    name: "Modal",
                    width: 488,
                    height: "auto",
                    adaptive: true,
                    scrollable: true,
                    clickToClose: false
                },
                {
                    opened: this.opened
                }
            );
        },
    },
    mounted() {
        setTimeout(() => {
            this.isMenuSticky = true;
            this.isBannerVisible = false;
        }, 5000);
        window.addEventListener('bannerRemoved', () => {
            this.isMenuSticky = true;
        });
    },
    beforeDestroy() {
        this.opened();
    }
};
</script>

<style lang="scss">
:root {
    --banner-height: 80px;
    --banner-visible: 1;
}

.Main {
    position: relative;
    z-index: 1;
    margin-top: calc(var(--banner-height) * var(--banner-visible));
    transition: margin-top 0.8s ease;
    padding-top: var(--menu-offset, 0);

    [lang="en"] & {
        margin-top: 72px;
    }
}

#app {
    position: relative;
    z-index: 1;
}

body.banner-hidden .Main {
    padding-top: 72px;
}
</style>
