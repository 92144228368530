<template>
    <div id="menu" class="MainMenu" :class="[
        { 'MainMenu--scrolled': documentIsScrolled },
        { 'MainMenu--en': $i18n.locale === 'en' },
        { 'MainMenu--raised': !isBannerVisible }
    ]" :key="$i18n.locale">
        <div class="Wrapper MainMenu-Box">
            <BaseLogo />
            <MainNav v-show="$screen.width > 959" />
            <LocaleSwitcher />
            <BaseButtonBurger v-show="$screen.width < 960" class="MainMenu-Burger" @click.native="show = !show">
                <img loading="lazy" src="@/assets/images/burger.svg" alt="" class="Burger-Img">
            </BaseButtonBurger>
            <transition v-show="$screen.width < 959" name="fade">
                <div v-show="show" class="MainMenu-Aside">
                    <div class="Wrapper MainMenu-AsideHeader">
                        <BaseLogo />
                        <BaseButtonBurger class="MainMenu-Burger" @click.native="show = !show">
                            <img src="@/assets/images/close.svg" alt="" class="Burger-Img">
                        </BaseButtonBurger>
                    </div>
                    <MainNav style="margin: 112px 0 135px;" @click.native="show = !show" />
                    <div class="Wrapper">
                        <a href @click.prevent="scrollTo('form'), show = !show" class="Button MainMenu-AsideButton">
                            <span class="Button-Text">
                                {{ $t('mainNav.button') }}
                            </span>
                            <div class="Button-Shadow"></div>
                        </a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import MainNav from "./MainNav";
import LocaleSwitcher from "./LocaleSwitcher";
import { scrollTo } from "../assets/utils/utils";

export default {
    name: "MainMenu",
    components: {
        LocaleSwitcher,
        MainNav,
    },
    data() {
        return {
            show: false,
            documentIsScrolled: false,
            headerTop: 68,
            bannerHeight: 0,
            isBannerVisible: true
        }
    },
    methods: {
        scrollTo,
        onScroll() {
            this.documentIsScrolled = window.scrollY > (this.headerTop + this.bannerHeight);
        },
        updateBannerHeight() {
            const banner = document.querySelector('.TelegramChanAdBanner')
            this.bannerHeight = this.$i18n.locale === 'ru' && banner ? banner.offsetHeight : 0;
        }
    },
    mounted() {
        this.headerTop = this.$screen.width < 959 ? 68 : 92;
        this.updateBannerHeight();
        this.documentIsScrolled = window.scrollY > this.headerTop;

        if (this.$i18n.locale === 'en') {
            document.documentElement.style.setProperty('--banner-visible', '0');
            this.isBannerVisible = false;
        }

        window.addEventListener('scroll', this.onScroll, { passive: true });
        window.addEventListener('resize', this.updateBannerHeight);
    },
    watch: {
        '$i18n.locale': {
            immediate: true,
            handler(newLocale) {
                if (newLocale === 'en') {
                    document.documentElement.style.setProperty('--banner-visible', '0');
                    this.isBannerVisible = false;
                }
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateBannerHeight)
    }
}
</script>

<style lang="scss">
.MainMenu {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1002;
    padding: 10px 0;
    height: 72px;
    transition: all 0.8s ease;
    background-color: var(--color-bg-menu);
    top: var(--banner-height, 80px);

    @media (max-width: $screen-l) {
        height: 58px;
    }

    &--scrolled {
        background-color: var(--color-bg-menu);
        box-shadow: 0 5px 5px var(--color-text-main2);
        margin-top: 0;
        top: 0;
        z-index: 1004;
        position: fixed;
    }

    &--en {
        top: 0 !important;
        margin-top: 0;
        z-index: 1004;
    }

    &--raised {
        top: 0;
        z-index: 1004;
    }

    &-Box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
    }

    &-Aside {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: var(--color-body);
        scrollbar-width: thin;
    }

    &-AsideHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 16px;
    }

    &-AsideButton {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        width: 220px
    }

    &-Burger {
        border: 2px solid var(--color-text-main2);
    }

    .Button {
        width: 100%;
    }
}

.Logo {
    width: 132px;

    @media (min-width: $screen-l) {
        width: 234px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
