<template>
    <div class="TelegramChanAdBanner" name="TelegramChanAdBanner" id="main-box">
        <div id="gradient">
            <div id="grid">
                <div id="smoke">
                    <div id="telegram-chan-ad-banner">
                        <div id="telegram-chan-ad-banner-content">
                            <h1 id="telegram-chan-ad-banner-h1">
                                Все секреты медиабаинга – в одном канале!
                            </h1>
                            <div id="chalk-line">
                                <img src="/chalk-line.png">
                            </div>
                            <span id="telegram-chan-ad-banner-span">
                                Прокачай свою экспертизу и карьеру: кейсы,
                                стратегии и инсайды от лидеров индустрии!
                            </span>
                        </div>
                        <div id="telegram-chan-ad-banner-right">
                            <div class="image-button-container">
                                <img src="/cat.png" id="cat-img">
                                <a id="telegram-chan-ad-link" href="https://t.me/+NNcmxljlTWRkYjUy">
                                    ПРИСОЕДИНИТЬСЯ
                                    <img src="/tg-icon.png" id="tg-icon">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
function delay() {
    window.onload = function () {
        const mainBox = document.getElementById("main-box");
        const actualHeight = mainBox.offsetHeight;
        document.documentElement.style.setProperty('--banner-height', actualHeight + 'px');
        document.documentElement.style.setProperty('--banner-visible', '1');
    }
}

export default {
    name: 'TelegramChanAdBanner',
    mounted() {
        delay();
    }
}
</script>

<style>
#main-box {
    position: relative;
    width: 100%;
    z-index: 1003;
    overflow: visible;

    @media (min-width: 1921px) {
        width: 100%;
    }

    @media (max-width: 1920px) {
        width: 100%;
    }

    @media (max-width: 960px) {
        display: none;
    }
}

#gradient {
    width: 100%;
    background: linear-gradient(to right, var(--color-text-main2), black 30%);
}

#smoke {
    width: 100%;
    background-image: url('/smoke.png');
    background-size: cover;
}

#grid {
    width: 100%;
    background-image: url('/grid.png');
    background-size: 40%;
    background-position: center;
    background-repeat: repeat;
}

#telegram-chan-ad-banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 15px 20px;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    background-color: var(--color-bg-menu);
    min-height: 80px;
}

#telegram-chan-ad-banner-content {
    flex: 1;
    min-width: 300px;
    max-width: 70%;
}

#telegram-chan-ad-banner-right {
    flex-shrink: 0;
    position: relative;
}

.image-button-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

#telegram-chan-ad-banner-h1 {
    color: var(--color-banner-bg);
    margin: 0;
    position: relative;
    text-transform: uppercase;
    font-weight: lighter;
    font-size: clamp(16px, 1.5vw, 24px);
}

#chalk-line {
    margin: 10px 0;
    width: 40%;
    transform: rotate(-1deg);
    position: relative;
    top: -10px;
    margin-bottom: 10px;
}

#chalk-line img {
    width: 100%;
    height: auto;
}

#telegram-chan-ad-banner-span {
    display: block;
    line-height: 1.5;
    margin-top: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: lighter;
    font-size: clamp(16px, 2vw, 24px);
}

#telegram-chan-ad-link {
    background-color: var(--color-text-main2);
    color: black;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    font-size: clamp(16px, 2vw, 24px);
    padding: 15px 30px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
}

#telegram-chan-ad-link:hover {
    background-color: var(--color-banner-bg);
}

#tg-icon {
    width: 30px;
    height: auto;
}

#cat-img {
    width: 100px;
    position: absolute;
    left: -25px;
    bottom: -20px;
    z-index: 2;
}

@media (max-width: 768px) {
    #telegram-chan-ad-banner {
        flex-direction: column;
        text-align: center;
    }

    #telegram-chan-ad-banner-content {
        max-width: 100%;
    }

    #chalk-line {
        margin: 10px auto;
    }

    #cat-img {
        display: none;
    }
}
</style>