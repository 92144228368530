<template>
  <div class="ModalArticle" name="ModalArticle">
    <BaseButtonBurger class="ModalArticle-Close" @click="hideModalArticle">
      <img loading="lazy" src="@/assets/images/close.svg" alt="close" class="icon" />
    </BaseButtonBurger>
    <div class="ModalArticle-Frontside">
      <h3 class="ModalArticle-Title">
        Топовый кейс
      </h3>
      <h3 class="ModalArticle-Title ModalArticle-Title-Second">
        с Facebook на Бразилию
        <img src="@/assets/images/flag-brazil.svg" alt="brazil" class="ModalArticle-Title-Icon" />
      </h3>
      <span class="ModalArticle-Profit">Профит</span>
      <span class="ModalArticle-Profit-Amount">55 470 €</span>
      <div class="ModalArticle-ROI">
        <span class="ModalArticle-ROI-Text">ROI</span>
        <span class="ModalArticle-ROI-Percent">113%</span>
      </div>
      <a
        href="https://partnerkin.com/blog/kejsy/keys_fb_brazil"
        target="_blank"
        class="ModalArticle-Button"
      >
        <span class="ModalArticle-Button-Text">
          читать
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalArticle",
  methods: {
    hideModalArticle() {
        this.$emit('hide-modal-article')
    }
  }
};
</script>

<style lang="scss">
.ModalArticle {
  position: relative;
  padding: 26px;
  text-align: center;
  background: url("../assets/images/modal-background.svg");
  background-position: center;
  background-size: cover;

  &--hidden {
    display: none !important;
  }

  &-Frontside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-Backside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-Title {
    display: inline-flex;
    font-size: 28px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    &-Second {
      flex-direction: column;
      align-items: center;
      color: #2BF12E;

      @media (min-width: $screen-l) {
        flex-direction: row;
      }
    }

    &-Icon {
      width: 52px;
      margin: 8px 0 0 0;

      @media (min-width: $screen-l) {
        width: 30px;
        margin: 0 0 0 8px;
      }
    }
  }

  &-Profit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 44px;
    margin: 18px auto 2px;
    background: url("../assets/images/modal-button-1.svg");
    color: #FFFFFF;
    font-size: 22.269px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;

    &-Amount {
      color: #FFFFFF;
      text-align: center;
      font-size: 43.333px;
      font-weight: 700;
      line-height: 57.843px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;

      @media (min-width: $screen-l) {
        font-size: 56.875px;
        line-height: 75.92px;
      }
    }
  }

  &-ROI {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    margin-bottom: 12px;
    background: url("../assets/images/modal-gradient-circle.svg");
    background-position: center;
    background-size: contain;

    @media (min-width: $screen-l) {
      width: 143px;
      height: 143px;
    }

    &-Text {
      color: #FFFFFF;
      text-align: center;
      font-size: 12.014px;
      line-height: 14.682px;
      font-weight: 300;
      font-style: normal;
      text-transform: uppercase;

      @media (min-width: $screen-l) {
        font-size: 19.618px;
        line-height: 23.975px;
      }
    }

    &-Percent {
      color: #222222;
      text-align: center;
      font-size: 21.259px;
      line-height: 25.98px;
      font-style: normal;
      font-weight: 800;
      text-transform: uppercase;

      @media (min-width: $screen-l) {
        font-size: 34.715px;
        line-height: 42.424px;
      }
    }
  }

  &-Button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 62px;
    background: url("../assets/images/modal-button-2.svg");

    &-Text {
      color: #FFFFFF;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0.14px;
      text-transform: uppercase;
    }
  }

  &-Close {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    pointer-events: auto;

    .icon {
      width: 20px;
    }
  }
}
</style>
